import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
import AccordionComponent from '../../../../components/Accordion';
export const _frontmatter = {
  "title": "AWS API Gateway with Cloudwatch",
  "path": "/knowledge/tech/aws",
  "date": "2023-02-19T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h1 {...{
          "id": "aws-api-gateway-with-cloudwatch",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#aws-api-gateway-with-cloudwatch",
            "aria-label": "aws api gateway with cloudwatch permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS API Gateway with Cloudwatch`}</h1>
        <h2 {...{
          "id": "overview",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#overview",
            "aria-label": "overview permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Overview`}</h2>
        <p>{`The following document is a step-by-step guide on integrating AWS API Gateway with Cloudwatch through the CDK`}</p>
        <h2 {...{
          "id": "stack",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#stack",
            "aria-label": "stack permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Stack`}</h2>
        <p>{`The following stack creates AWS API Gateway with authentication provided by Cognito and logging provided by Cloudwatch.`}</p>
        <h3 {...{
          "id": "steps",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#steps",
            "aria-label": "steps permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Steps`}</h3>
        <p>{`The following are the steps required to add Auth & Cloudwatch to the API Gateway. `}<a parentName="p" {...{
            "href": "./api-gateway-with-auth#demo-api-gateway-with-auth-enabled"
          }}>{`This document`}</a>{` goes in more depth about adding Authentication`}</p>
        <h4 {...{
          "id": "request-to-log-diagram",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#request-to-log-diagram",
            "aria-label": "request to log diagram permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Request to log diagram`}</h4>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "682px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/418ab6ad714f5d9656d7d9a1cb38c298/160a3/api-gateway-with-cloudwatch-diagram.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "35.333333333333336%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAyElEQVQoz42QWQqEQAxEvf/1vICgILaI+75keDVE/BBmGoqkK5VKd6LjOGzbNtv3XdEP/HmeitQcnOu6Xmvco3mebRxH6/veyPM8t7IsJViWRXzXdda2rfIsyxQZTgTUhmFQjwxprKpKU4mI3KxpGnFFUehOTjNDqNV1bSEEm6bpa8gkBAgR8FoHw+CI67oqPr/MUIwYdBv6rnwHGKVpqm/4XuEdcOjR8UpWlCSJ9HDRU/wv3JCvxnEsYPzT0F/9BlaAAesC5HAfS/ofzV76aCYAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "diagram",
                "title": "diagram",
                "src": "/static/418ab6ad714f5d9656d7d9a1cb38c298/160a3/api-gateway-with-cloudwatch-diagram.png",
                "srcSet": ["/static/418ab6ad714f5d9656d7d9a1cb38c298/5a46d/api-gateway-with-cloudwatch-diagram.png 300w", "/static/418ab6ad714f5d9656d7d9a1cb38c298/0a47e/api-gateway-with-cloudwatch-diagram.png 600w", "/static/418ab6ad714f5d9656d7d9a1cb38c298/160a3/api-gateway-with-cloudwatch-diagram.png 682w"],
                "sizes": "(max-width: 682px) 100vw, 682px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "api-gateway--auth",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#api-gateway--auth",
            "aria-label": "api gateway  auth permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`API Gateway + Auth`}</h4>
        <ol>
          <li parentName="ol">{`Create Cognito User Pool`}
            <ol parentName="li">
              <li parentName="ol">{`Create an app client`}</li>
              <li parentName="ol">{`Create a domain`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Create a REST API Gateway`}
            <ol parentName="li">
              <li parentName="ol">{`Create a resource `}<inlineCode parentName="li">{`/ditto`}</inlineCode></li>
              <li parentName="ol">{`Create a `}<inlineCode parentName="li">{`GET`}</inlineCode>{` HTTP Integration for the resource`}</li>
              <li parentName="ol">{`Create Cognito authorizer for the API Gateway`}
                <ul parentName="li">
                  <li parentName="ul">{`Uses the created Cognito User Pool instance to create the authorizer`}</li>
                </ul>
              </li>
              <li parentName="ol">{`Set the authorization handler for the resource's `}<inlineCode parentName="li">{`GET`}</inlineCode>{` method as the created Cognito's authorizer`}</li>
            </ol>
          </li>
        </ol>
        <h4 {...{
          "id": "api-gateway--cloudwatch",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#api-gateway--cloudwatch",
            "aria-label": "api gateway  cloudwatch permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`API Gateway + Cloudwatch`}</h4>
        <ol>
          <li parentName="ol">{`Create a deployment instance of the REST API Gateway`}</li>
          <li parentName="ol">{`Create a log group`}</li>
          <li parentName="ol">{`Use the same Rest API Gateway from API Gateway + Auth`}</li>
          <li parentName="ol">{`Create a dev stage with custom access logging & cloudwatch error/info logs enabled`}</li>
        </ol>
        <h3 {...{
          "id": "cdk-code",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#cdk-code",
            "aria-label": "cdk code permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`CDK Code`}</h3>
        <AccordionComponent summaryText="View code" mdxType="AccordionComponent">
          <pre><code parentName="pre" {...{
              "className": "language-ts"
            }}>{`import * as cdk from 'aws-cdk-lib'
import * as apiGateway from 'aws-cdk-lib/aws-apigateway'
import * as cognito from 'aws-cdk-lib/aws-cognito'
import * as logs from 'aws-cdk-lib/aws-logs'

export class CdkStack extends cdk.Stack {
  constructor(scope: cdk.App, id: string, props?: cdk.StackProps) {
    super(scope, id, props)

    // SECTION: API Gateway + Auth
    // Create Cognito user pool
    const USER_POOL_ID = 'APIgatewayUserPool'
    const userPool = new cognito.UserPool(this, USER_POOL_ID, {
      // Allow users to sign-up
      selfSignUpEnabled: true,
      autoVerify: {
        email: true,
      },
      standardAttributes: {
        // By default, Cognito verify sign-ups. When the email isn't required-
        // Cognito hosted UI won't display it when singing up
        email: {
          required: true,
        },
      },
    })

    // Add an App Client. Necessary for the hosted API and-
    // for other apps to interact with Cognito API programmatically
    userPool.addClient('APIgatewayUserPoolClient')

    // Create a domain for the Hosted UI
    userPool.addDomain('Domain', {
      cognitoDomain: {
        // "onetwo" are meant to make the prefix unique
        domainPrefix: 'apigatewayauthonetwo',
      },
      // You could provide a custom domain instead
      // customDomain: 'myClient.website.com'
    })

    // Create REST API Gateway
    const api = new apiGateway.RestApi(this, 'ditto-api', {
      // Automatically create a Cloudwatch role for this API
      cloudWatchRole: true,
    })

    // Declare HTTP Integration type with target as pokeAPI to be used later
    const httpIntegration = new apiGateway.HttpIntegration(
      'https://pokeapi.co/api/v2/pokemon/ditto'
    )

    // Add \`/ditto\` resource to the API Gateway
    const dittoResource = api.root.addResource('ditto')

    // Create Cognito authorizer for the API Gateway
    const cognitoAuth = new apiGateway.CognitoUserPoolsAuthorizer(
      this,
      'ApiGatewayAuth',
      {
        cognitoUserPools: [userPool],
      }
    )

    // Create a protected GET method with Cognito authorizer for \`/ditto\` resource
    dittoResource.addMethod('GET', httpIntegration, {
      authorizer: cognitoAuth,
      authorizationType: apiGateway.AuthorizationType.COGNITO,
    })
    // API Gateway + Auth end

    // SECTION: API Gateway + Cloudwatch start
    const deployment = new apiGateway.Deployment(this, 'Deployment', { api })

    // Create a log group for Cloudwatch
    const devLogGroup = new logs.LogGroup(this, 'DevLogs')

    // Create a dev stage for the API Gateway
    new apiGateway.Stage(this, 'dev', {
      stageName: 'dev',
      deployment,
      // Enables Custom Access Logging
      accessLogDestination: new apiGateway.LogGroupLogDestination(devLogGroup),
      accessLogFormat: apiGateway.AccessLogFormat.jsonWithStandardFields(),
      // Enabled Cloudwatch info & error loggings
      loggingLevel: apiGateway.MethodLoggingLevel.INFO,
    })
    // API Gateway + Cloudwatch end
  }
}
`}</code></pre>
        </AccordionComponent>
        <h2 {...{
          "id": "resources",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#resources",
            "aria-label": "resources permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Resources`}</h2>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/Working-with-log-groups-and-streams.html"
            }}>{`Log groups`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/cdk/api/v1/docs/@aws-cdk_aws-apigateway.Deployment.html"
            }}>{`REST API Deployment`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/cdk/api/v1/docs/aws-cognito-readme.html"
            }}>{`AWS CDK Cognito`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/cdk/api/v1/docs/aws-apigateway-readme.html"
            }}>{`AWS CDK API Gateway`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=oFSU6rhFETk"
            }}>{`(External) Integrating AWS API Gateway with Cognito`}</a>
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Recommended YouTube channel`}</strong></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/cdk/api/v2/docs/aws-cdk-lib.aws_logs-readme.html"
            }}>{`AWS CDK logs`}</a></li>
        </ul>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      